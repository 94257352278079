import strFunc from "@/lib/lyg/string";
import StudyDetailBox from "./study_detail_box";

function SectionArea(props: any) {
  let myProps = {
    info: {},
    Style: {},
    go_detail_view: (inData: any) => { },
    ...props
  };
  let info = myProps.info;
  let Style = myProps.Style;
  let map_detail_arr = info.detail_arr;

  const get_section_tags = () => {
    let section_arr: any = [];
    if (info["section_arr"]) {
      section_arr = info["section_arr"];
    }
    let section_tags = section_arr.map((item: any, idx: number) => {
      let row_map_detail: any = null;
      for (let i = 0; i < map_detail_arr.length; i++) {
        let tmp_map_detail = map_detail_arr[i];
        if (tmp_map_detail["a_section_name"] == item["a_title"]
          && tmp_map_detail["a_unit_name"] == "") {
          row_map_detail = tmp_map_detail;
        }
      }
      let row_map_detail_text = "없음";
      let row_map_detail_style: any = {
        color: "gray",
      };
      if (row_map_detail != null) {
        row_map_detail_text = "미완료";
        row_map_detail_style["color"] = "orange";
        if (row_map_detail["a_is_success"] == "1") {
          row_map_detail_text = "완료";
          row_map_detail_style["color"] = "blue";
        }
      }

      return (
        <div key={idx}>
          <div className={Style.section_title}
            onClick={() => {
              myProps.go_detail_view({
                section_name: item["a_title"],
                unit_name: "",
                study_detail_info: row_map_detail,
              });
            }}
          >
            {idx + 1}. {item["a_title"]}
            <div className={Style.study_detail_right}>
              <span style={row_map_detail_style}>
                {row_map_detail_text}
              </span>
            </div>
          </div>
          <div style={{ paddingLeft: 10 }}>
            {get_study_detail_tags({ section_info: item })}
          </div>
        </div>
      );
    });

    if (strFunc.is_empty(section_tags)) {
      section_tags = (
        <div style={{ textAlign: "center", fontSize: "22px", height: "40px", lineHeight: "35px" }}>
          섹션이 없습니다.
        </div>
      );
    }

    return section_tags;
  };

  const get_study_detail_tags = (inData: any) => {
    let opt_obj = {
      section_info: null,
      ...inData
    };
    let section_info = opt_obj.section_info;
    let study_detail_arr: any = [];
    if (info["study_detail_arr"]) {
      for (let i = 0; i < info["study_detail_arr"].length; i++) {
        let study_detail = info["study_detail_arr"][i];
        if (study_detail["a_section_seq"] == section_info["a_seq"]) {
          study_detail_arr.push(study_detail);
        }
      }
    }

    let study_detail_tags = study_detail_arr.map((item: any, idx: number) => {
      return (
        <StudyDetailBox
          key={idx}
          info={info}
          Style={Style}
          section_info={section_info}
          row_num={idx}
          study_detail={item}
          go_detail_view={myProps.go_detail_view}
        ></StudyDetailBox>
      );
    });

    return study_detail_tags;
  };

  return (
    <div className={Style.section_wrap}>
      {get_section_tags()}
    </div>
  );
};
export default SectionArea;