//import MatterFunc from "@/pages/mobile/playground/math/common/func/matter_func";
import default_game_data_json from "@/pages/mobile/playground/math/common/data/default_game_data";
import PlusFunc from "./plus";
import MinusFunc from "./minus";
import MultiplyFunc from "./multiply";
import DivideFunc from "./divide";

class MatterFunc{
  static get_matter_arr_by_opt=(inData:any)=>{
    let opt_obj = {
      game_data:{},
      ...inData
    };
    let game_data = opt_obj.game_data;
    let matter_arr:any = [];

    let dificult_min_i=(game_data.matter_total/4)*3;
    dificult_min_i=game_data.matter_total;
    
    for(let i=0;i<game_data.matter_total;i++){
      let matter:any = {
        ...default_game_data_json.matter,
        type: game_data.sort,//minus, multiply, divide
        level: game_data.level,
        val_arr:[],
        answer:0,
      };
      
      if(game_data.sort=="plus"){
        if(i>dificult_min_i){
          matter=PlusFunc.get_matter({
            level:game_data.level+1,
            default_matter:matter,
          });
        }else{
          matter=PlusFunc.get_matter({
            level:game_data.level,
            default_matter:matter,
          });
        }
      }else if(game_data.sort=="minus"){
        if(i>dificult_min_i){
          matter=MinusFunc.get_matter({
            level:game_data.level+1,
            default_matter:matter,
          });
        }else{
          matter=MinusFunc.get_matter({
            level:game_data.level,
            default_matter:matter,
          });
        }
      }else if(game_data.sort=="multiply"){
        if(i>dificult_min_i){
          matter=MultiplyFunc.get_matter({
            level:game_data.level+1,
            default_matter:matter,
          });
        }else{
          matter=MultiplyFunc.get_matter({
            level:game_data.level,
            default_matter:matter,
          });
        }
      }else if(game_data.sort=="divide"){
        if(i>dificult_min_i){
          matter=DivideFunc.get_matter({
            level:game_data.level+1,
            default_matter:matter,
          });
        }else{
          matter=DivideFunc.get_matter({
            level:game_data.level,
            default_matter:matter,
          });
        }
      }

      matter_arr.push(matter);
    }

    return matter_arr;
  };
}
export default MatterFunc;