import LygMath from '@/lib/lyg/math';

class DivideFunc
{
  static get_matter=(inData:any)=>{
    let opt_obj = {
      level:1,
      default_matter:{},
      ...inData
    };
    let default_matter=opt_obj.default_matter;
    let level=opt_obj.level;
    let matter:any = {
      ...default_matter,
      level: level,
      val_arr:[],
      answer:0,
    };
    let val_arr=[];
    let a_num=0;
    let b_num=0;
    let c_num=0;
    if(level==1){
      a_num=LygMath.random(2,9);
      b_num=LygMath.random(2,9);
    }else{
      a_num=LygMath.random(2,20*level);
      b_num=LygMath.random(2,20*level);
    }
    c_num=a_num*b_num;
    val_arr.push(c_num);
    val_arr.push(a_num);

    let answer=b_num;

    matter["val_arr"]=val_arr;
    matter["answer"]=answer;

    return matter;
  };
}
export default DivideFunc;