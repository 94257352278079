import { useState, useRef, useEffect, useMemo, useCallback, forwardRef } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import { useSelector, useDispatch } from 'react-redux';
import getXcolumnJson from "./xcolumn/list";
import { default_info } from "@/pcomponents/common/content/edu/month_prescribe/data/default_data";
import WriteArea from "@/pages/mobile/manage/edu/month_prescribe/common/write";
import LoadingComponent from "@/pcomponents/common/content/loading";
import { default_daily_report_last_info,get_last_daily_report_by_month_prescribe_info } from "@/pcomponents/common/content/edu/daily_report_last/data/default_data";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import MonthPrescribeWriteFunc from "@/pcomponents/common/content/edu/month_prescribe/write/write_func";
import ViewPopupContent from '../view_content/index';

function WritePopupContent(props: any) {
  let myProps = {
    isUpdate: false,
    stu_seq: "",
    stu_name: "",
    select_date: "",
    rowData: {},
    list: (inData: any) => { },
    is_show_view_popup_btn: true,
    is_show_close_btn: true,
    ...props,
  };
  let user = useSelector((state: any) => state.user);
  let iam_mcomp_user_seq = "";
  let iam_mcomp_user_name = "";
  let iam_mcomp_seq = "";
  let iam_mcomp_user_grade = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    iam_mcomp_user_name = user.select_mcomp_link["a_mcomp_user_name"];
    iam_mcomp_user_grade = user.select_mcomp_link["a_user_grade"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }

  const xColumnArr = getXcolumnJson();
  const pageData = {
    "write_btn_text": "등록",
    "confirm_msg": "등록 하시겠습니까?",
  };
  if (props.isUpdate) {
    pageData["write_btn_text"] = "수정";
    pageData["confirm_msg"] = "수정 하시겠습니까?";
  }

  const init_data = {
    ...default_info,
    "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
    "a_seq": "",
    "a_stu_seq": myProps.stu_seq,
    "a_stu_name": myProps.stu_name,
    "a_reserve_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
    "a_writer_seq": iam_mcomp_user_seq,
    "a_writer_name": iam_mcomp_user_name,
    "a_is_write": "1",
    "a_write_date": DateFunc.get_date_format(new Date(), "Y-m-d h:i:s")
  };

  const [rowData, setRowData] = useState({
    ...init_data,
  });
  const [last_daily_report, set_last_daily_report] = useState<any>({...default_daily_report_last_info});
  const last_row_info_ref = useRef(rowData);
  const [refresh, set_refresh] = useState(false);
  const [loading_data, setLoadingData] = useState({
    is_display: false,
    text: "로딩중.."
  });

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "write",//view
    isUpdate: false,
    rowData: {},
    has_header: true,
    title: "팝업",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {
    if (props.isUpdate) {
      let changeRowData: any = {
        ...rowData,
        ...props.rowData
      };
      setRowData(changeRowData);
      list({ row_data: changeRowData });
    } else {
      get_writer_info_by_ajax();
      if (!strFunc.is_empty(myProps.select_date)) {
        let changeRowData: any = {
          ...rowData,
          a_reserve_date: myProps.select_date,
          a_ymd: strFunc.getNumber2(myProps.select_date),
        };
        setRowData(changeRowData);
      }
    }
  }, []);

  const list = (inData: any) => {
    let opt_obj = {
      row_data: rowData,
      ...inData
    };
    if (strFunc.is_empty(opt_obj["row_data"]["a_seq"])) {
      return false;
    }
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    let row_pri_val = opt_obj["row_data"]["a_ymd"] + "," + opt_obj["row_data"]["a_seq"];
    let formJsonData:any = {
      "s_pri_arr": [row_pri_val],
      "s_addon_user": "1",
      "s_addon_counsel": "1",
      "s_addon_daily_report_last": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    formJsonData["api_key"] = comp_api_key;
    formJsonData["api_user_seq"] = iam_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/month_prescribe/list', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            let tmp_row_data = {
              ...init_data,
              ...response.data["data"]["info_arr"][0]
            };
            setRowData(tmp_row_data);
            last_row_info_ref.current = tmp_row_data;
            let tmp_last_report_info=get_last_daily_report_by_month_prescribe_info({
              row_data: tmp_row_data,
              end_date:tmp_row_data["a_reserve_date"],
            });
            set_last_daily_report(tmp_last_report_info);
          }
        } else {

        }
      });
  };

  const get_writer_info_by_ajax = () => {
    if (props.isUpdate) {
      return false;
    }
    let iam_mcomp_user_name = "";
    iam_mcomp_user_name = user.select_mcomp_link["a_mcomp_user_name"];

    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    let formJsonData:any = {
      "s_pri_arr": [iam_mcomp_user_seq],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    formJsonData["api_key"] = comp_api_key;
    formJsonData["api_user_seq"] = iam_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            let writer_info = response.data["data"]["info_arr"][0];
            if (props.isUpdate == false) {
              setRowData({
                ...rowData,
                a_subject_name: writer_info["a_main_subject"],
              });
            }
          }
        } else {

        }
      });
  };

  const goWrite = () => {
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }

    let w_result_data=MonthPrescribeWriteFunc.check_write_data_row({
      info: rowData,
      last_daily_report: last_daily_report,
    });

    if(w_result_data.is_able==false){
      alert(w_result_data.msg);
      return false;
    }

    let wRowData:any = { ...rowData };
    if (props.isUpdate == false) {
      wRowData["a_ymd"] = strFunc.getNumber2(wRowData["a_reserve_date"]);
    }
    wRowData["a_is_reserve"]="";
    let formJsonData: any = {
      "data_arr": [wRowData],
      "is_default_val": "1",
      "is_update": "",
    };
    if (props.isUpdate) {
      formJsonData["is_default_val"] = "";
      formJsonData["is_update"] = "1";
    }
    formJsonData["api_key"] = comp_api_key;
    formJsonData["api_user_seq"] = iam_mcomp_user_seq;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/month_prescribe/write', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setLoadingData({
            is_display: true,
            text: "저장되었습니다."
          });
          setTimeout(() => {
            setLoadingData({
              is_display: false,
              text: ""
            });
          }, 1200);
          last_row_info_ref.current = response.data["data"][0];
          let priVal = xColumnArr.func.getPriValByDataRow({
            xColumnArr: xColumnArr,
            rowData: response.data["data"][0]
          });
          props.callback({ select_seq: priVal });
          props.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });

  };

  const goDelete = () => {
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    if (!confirm("삭제 하시겠습니까?")) {
      return false;
    }

    let wRowData = { ...rowData };
    if (strFunc.is_empty(wRowData["a_seq"])) {
      alert("키값없음.");
      return false;
    }
    let formJsonData: any = {
      data_arr: [wRowData],
    };
    formJsonData["api_key"] = comp_api_key;
    formJsonData["api_user_seq"] = iam_mcomp_user_seq;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/month_prescribe/delete', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setLoadingData({
            is_display: true,
            text: "삭제되었습니다."
          });
          setTimeout(() => {
            setLoadingData({
              is_display: false,
              text: ""
            });
          }, 1200);
          props.callback({});
          props.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const openViewPopup = (inData: any) => {
    let opt_obj = {
      ...inData
    };
    setPopupData({
      ...popupData,
      "isOpen": true,
      "isUpdate": myProps.isUpdate,
      "rowData": rowData,
      "title": "보기",
      "sort": "view",
      width: "800px",
      height: "100%",
    });
  };

  return (
    <div>
      <LoadingComponent
        is_display={loading_data.is_display}
        text={loading_data.text}></LoadingComponent>
      <div>
        <WriteArea
          rowData={rowData}
          is_view_stu_info={true}
          setRowData={setRowData}
          isUpdate={myProps.isUpdate}
          set_refresh={() => { set_refresh(!refresh); }}
          list={(inData:any)=>{
            list({});
            myProps.list({});
          }}
          last_daily_report={last_daily_report}
          set_last_daily_report={set_last_daily_report}
        ></WriteArea>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        {(myProps.isUpdate&&rowData.a_writer_seq==iam_mcomp_user_seq) &&
          <button className="btn btn-red" onClick={goDelete}>삭제</button>
        }
        {myProps.is_show_view_popup_btn &&
          <button className="btn btn-line-gray" onClick={()=>{openViewPopup({});}}>보기</button>
        }
        {myProps.is_show_close_btn &&
          <button className="btn btn-line-gray" onClick={() => { myProps.closePopup(); }}>닫기</button>
        }
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} has_header={popupData.has_header} y={"0"} is_border_radius={false} >
          
          {popupData.sort === "view" &&
            <ViewPopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} 
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
              ></ViewPopupContent>
          }
        </LayerPopup>
      }
    </div>
  );
}

export default WritePopupContent;
