import clock_ico_img from "@/img/mobile/game/math/clock_ico.png";
import strFunc from "@/lib/lyg/string";

function TopInfoArea(props: any) {
  let myProps = {
    Style: {},
    char_user_info: {},
    game_data_ref: null,
    ...props
  };
  let Style = myProps.Style;
  let char_user_info = myProps.char_user_info;
  let game_data_ref = myProps.game_data_ref;

  let elapse_msec = strFunc.uncomma(game_data_ref.current.elapse_msec);
  if (!strFunc.is_empty(elapse_msec)) {
    elapse_msec = Math.floor(elapse_msec / 1000);
  }
  let elapse_time_json = strFunc.secondsToTimeJson(elapse_msec);

  return (
    <div>
      <div className={Style.top_time_wrap}>
        <img src={clock_ico_img} className={Style.top_clock_img} />
        <div className={Style.top_clock_box}>
          <div>학습시간</div>
          <div className={Style.top_clock_text}>{elapse_time_json.i}:{elapse_time_json.s}</div>
        </div>
        <div className={Style.top_clock_right}>
          <span style={{ marginRight: 15, fontSize: 18 }}>
            <span style={{ color: "green" }}>{game_data_ref.current.matter_correct_cnt}</span>
            <span style={{ color: "red", marginLeft: 5 }}>{game_data_ref.current.matter_now_idx-game_data_ref.current.matter_correct_cnt}</span>
          </span>
          {strFunc.str_pad({ "str": game_data_ref.current.matter_now_idx + 1, "pad_length": 2 })}/
          {game_data_ref.current.matter_total}
        </div>
      </div>
    </div>
  );
};
export default TopInfoArea;