import MobileLayout from "@/pcomponents/mobile/layout/layout";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PushListArea from "./area/list_area";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import SearchArea from "./area/search";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import ViewPopupContent from "@/pages/mobile/push/popup/view";
import LoadingComponent from "@/pcomponents/common/content/loading";
import TopMoveButton from "@/pcomponents/common/content/list/top_button";

function PushList() {
  let user = useSelector((state: any) => state.user);
  const PushListAreaRef = useRef<any>(null);
  const SearchAreaRef = useRef<any>(null);
  const [listOpt, setListOpt] = useState({
    'now_page': 1,
    'num_per_page': 30,
    'order_id': 'a_create_date DESC',
    'order_type': '',
    's_date_type': 'a_create_date',
    's_start_date': '',
    's_end_date': '',
    "is_add_idx_info": "1",
    "s_owner_seq": user.user_seq,
    "s_title":"",
    "s_is_receive": "",
    "s_is_read": "",
    "s_par_id": "",
    "s_mcomp_seq": "",
    "s_addon_user": "1",
    "s_addon_mcomp": "1",
    "s_addon_par_info": "1",
  });
  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [checked_seq_arr, set_checked_seq_arr] = useState([]);
  const [loading_data, set_loading_data] = useState({
    is_display: false,
    text: "완료",
  });

  useEffect(() => {
    list({});
  }, [user]);

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "view",
    rowData: {},
    title: "보기",
    width: "100%",
    height: "100%",
    marginTop: "0px",
  });

  const list = (inData: any) => {
    let tmp_list_opt = { 
      ...listOpt,
      ...inData
     };
     setListOpt(tmp_list_opt);
    if (strFunc.is_empty(tmp_list_opt.s_owner_seq)) {
      return false;
    }
    set_checked_seq_arr([]);
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/basic/push/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
        } else {

        }
      });
  };

  const get_select_info_arr = () => {
    let select_info_arr: any = [];
    for (let i = 0; i < infoArr.length; i++) {
      let info = infoArr[i];
      let row_pri_val = info["a_ymd"] + "," + info["a_seq"];
      if (strFunc.str_in_array(row_pri_val, checked_seq_arr) != -1) {
        select_info_arr.push(info);
      }
    }
    return select_info_arr;
  };

  const delete_by_ajax = () => {
    let select_info_arr = get_select_info_arr();
    if (select_info_arr.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    if (!confirm(select_info_arr.length + "건을 삭제 하시겠습니까?")) {
      return false;
    }
    let form_json_data = {
      "data_arr": select_info_arr,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/basic/push/delete', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert(response.data["msg"]);
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_readed_by_ajax = () => {
    let select_info_arr = get_select_info_arr();
    if (select_info_arr.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    if (!confirm(select_info_arr.length + "건을 읽음처리 하시겠습니까?")) {
      return false;
    }
    
    let change_push_data_arr = [];
    for (let i = 0; i < select_info_arr.length; i++) {
      let push_info = select_info_arr[i];
      if (push_info["a_is_receive"] == "1"
        && push_info["a_is_read"] != "1") {
        change_push_data_arr.push({
          a_ymd: push_info["a_ymd"],
          a_seq: push_info["a_seq"],
          a_is_read: "1",
          a_read_date: DateFunc.get_date_format(new Date(), "Y-m-d h:i:s"),
        });
      }
    }
    if (change_push_data_arr.length == 0) {
      set_loading_data({
        is_display: true,
        text: "데이터 없음",
      });
      setTimeout(() => {
        set_loading_data({
          is_display: false,
          text: "완료",
        });
      }, 1200);
      return false;
    }
    let form_json_data = {
      "data_arr": change_push_data_arr,
      is_update: "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/basic/push/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          list({});
          set_loading_data({
            is_display: true,
            text: "완료",
          });
          setTimeout(() => {
            set_loading_data({
              is_display: false,
              text: "완료",
            });
          }, 1000);
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const on_click_all_check = (inData: any) => {
    let opt_obj = {
      is_check: false,
      ...inData
    };
    let new_checked_seq_arr: any = [];
    if (opt_obj.is_check) {
      for (let i = 0; i < infoArr.length; i++) {
        let info = infoArr[i];
        let row_pri_val = info["a_ymd"] + "," + info["a_seq"];
        new_checked_seq_arr.push(row_pri_val);
      }
    }
    set_checked_seq_arr(new_checked_seq_arr);
  };

  const openViewPopup = (row_data: any) => {
    setPopupData({
      ...popupData,
      "rowData": row_data,
      "isOpen": true,
      "sort": "view",
    });
  };

  return (
    <MobileLayout>
      <SearchArea
        ref={SearchAreaRef}
        listOpt={listOpt}
        list={(inData: any) => {
          list(inData);
        }}
      ></SearchArea>
      <div className="text-center">
        <span style={{ color: "blue" }}>{countInfo.tot}</span> 검 검색됨.
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ display: "inline-block", marginLeft: 10 }}>
          <input type="checkbox"
            onChange={(e: any) => { on_click_all_check({ is_check: e.target.checked }); }}
            style={{ width: 20, height: 20 }} />
          <span className="ml-1">전체</span>
        </div>
        <div className="text-right" style={{ flexGrow: "1" }}>
          <button className="btn btn-dark" onClick={go_readed_by_ajax}>읽음처리</button>
          <button className="btn btn-red ml-1" onClick={delete_by_ajax}>삭제</button>
        </div>
      </div>
      <PushListArea
        ref={PushListAreaRef}
        infoArr={infoArr}
        onRowClick={(inRowData: any) => { openViewPopup(inRowData); }}
        checked_seq_arr={checked_seq_arr}
        set_checked_seq_arr={set_checked_seq_arr}
      ></PushListArea>
      <Paging now_page={listOpt.now_page}
        num_per_page={listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}></Paging>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} marginTop={popupData.marginTop} y="0px">
          {popupData.sort === "view" &&
            <ViewPopupContent
              rowData={popupData.rowData}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
              list_refresh={() => { list({}); }}
            >
            </ViewPopupContent>
          }
        </LayerPopup>
      }
      <LoadingComponent
        is_display={loading_data.is_display}
        text={loading_data.text}
      ></LoadingComponent>
      <TopMoveButton></TopMoveButton>
    </MobileLayout>
  );
}

export default PushList;